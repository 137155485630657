import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BusinessOwnerAuthGuard } from './guards/business-owner-auth.guard';
import { BusinessByIdResolverService } from './services/resolver/business-by-id/business-by-id-resolver.service';
import { BusinessOwnerResolverService } from './services/resolver/business-owner/business-owner-resolver.service';
import { BusinessByUrlResolverService } from './services/resolver/business/business-by-url-resolver.service';
import { EditHolidaysResolverService } from './services/resolver/holidays/edit-holidays-resolver.service';
import { HolidaysResolverService } from './services/resolver/holidays/holidays-resolver.service';
import { MenuByIdResolverService } from './services/resolver/menu-by-id/menu-by-id-resolver.service';
import { MenuItemByIdResolverService } from './services/resolver/menu-item-by-id/menu-item-by-id-resolver.service';
import { MenuSectionResolverService } from './services/resolver/menu-section/menu-section-resolver.service';
import { EditOpeningHoursResolverService } from './services/resolver/opening-hours/edit-opening-hours-resolver.service';
import { OpeningHoursResolverService } from './services/resolver/opening-hours/opening-hours-resolver.service';
import { TableByBusinessResolverService } from './services/resolver/table/table-by-business-resolver.service';
import { BusinessOperatorAuthGuard } from './guards/business-operator-auth.guard';
import { BusinessOperatorResolverService } from './services/resolver/business-operator/business-operator-resolver.service';
import { OrderForTableResolverService } from './services/resolver/order/order-for-table-resolver.service';
import { MainRoutingGuard } from './guards/main-routing.guard';
import { PrivilegeAllResolverService } from './services/resolver/privilege/privilege-all-resolver.service';
import { OrderingPage } from './public/ordering/ordering.page';
import { PaymentFeatureGuard } from './guards/payment-feature.guard';
import { VersionedMenuResolverService } from './services/resolver/menu/versioned-menu-resolver.service';
import { PreviewVersionedMenuResolverService } from './services/resolver/menu/preview-versioned-menu-resolver.service';

const routes: Routes = [
  {
    path: 'businesses',
    loadChildren: () => import('./business-owner/home/home.module').then(m => m.HomePageModule),
    canActivate: [BusinessOwnerAuthGuard],
    resolve: {
      data: BusinessOwnerResolverService
    }
  },
  {
    path: '',
    loadChildren: () => import('./auth/start/start.module').then(m => m.StartPageModule),
    canActivate: [MainRoutingGuard]
  },
  {
    path: 'welcome',
    loadChildren: () => import('./public/welcome/welcome.module').then(m => m.WelcomePageModule)
  },

  {
    path: 'unauthorized',
    loadChildren: () => import('./auth/unauthorized/unauthorized.module').then(m => m.UnauthorizedPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'businesses/add',
    loadChildren: () => import('./business-owner/business/add-business/add-business.module').then(m => m.AddBusinessPageModule),
    canActivate: [BusinessOwnerAuthGuard]
  },
  {
    path: 'businesses/:businessId/manager',
    loadChildren: () => import('./business-owner/business/business-manager/business-manager.module').then(m => m.BusinessManagerPageModule),
    canActivate: [BusinessOwnerAuthGuard],
    resolve: {
      data: BusinessByIdResolverService
    }
  },
  {
    path: 'logout',
    loadChildren: () => import('./auth/logout/logout.module').then(m => m.LogoutPageModule),
    canActivate: [BusinessOwnerAuthGuard]
  },
  {
    path: 'businesses/:businessId/edit',
    loadChildren: () => import('./business-owner/business/edit-business/edit-business.module').then(m => m.EditBusinessPageModule),
    canActivate: [BusinessOwnerAuthGuard],
    resolve: {
      data: BusinessByIdResolverService
    }
  },
  {
    path: 'businesses/:businessId/menus/:menuId/edit',
    loadChildren: () => import('./business-owner/business/edit-menu/edit-menu.module').then(m => m.EditMenuPageModule),
    canActivate: [BusinessOwnerAuthGuard],
    resolve: {
      data: MenuByIdResolverService
    }
  },

  {
    path: 'businesses/:businessId/menus/:menuId/menu-sections/:menuSectionId/edit',
    canActivate: [BusinessOwnerAuthGuard],
    resolve: {
      data: MenuSectionResolverService
    },
    loadChildren: () => import('./business-owner/business/edit-menu-section/edit-menu-section.module').then(m => m.EditMenuSectionPageModule)
  },
  {
    path: 'businesses/:businessId/menus/:menuId/menu-sections/:menuSectionId/menu-items/:menuItemId/edit',
    loadChildren: () => import('./business-owner/business/edit-menu-item/edit-menu-item.module').then(m => m.EditMenuItemPageModule),
    canActivate: [BusinessOwnerAuthGuard],
    resolve: {
      data: MenuItemByIdResolverService
    }
  },
  {
    path: 'businesses/:businessId/opening-hours',
    loadChildren: () => import('./business-owner/business/opening-hours/opening-hours.module').then(m => m.OpeningHoursPageModule),
    canActivate: [BusinessOwnerAuthGuard],
    resolve: {
      data: OpeningHoursResolverService
    }
  },
  {
    path: 'businesses/:businessId/holidays',
    loadChildren: () => import('./business-owner/business/holidays/holidays.module').then(m => m.HolidaysPageModule),
    canActivate: [BusinessOwnerAuthGuard],
    resolve: {
      data: HolidaysResolverService
    }
  },
  {
    path: 'businesses/:businessId/order-export',
    loadChildren: () => import('./business-owner/business/order-export/order-export.module').then(m => m.OrderExportPageModule),
    canActivate: [BusinessOwnerAuthGuard],
  },
  {
    path: 'businesses/:businessId/holidays/:holidayId/edit',
    loadChildren: () => import('./business-owner/business/edit-holidays/edit-holidays.module').then(m => m.EditHolidaysPageModule),
    canActivate: [BusinessOwnerAuthGuard],
    resolve: {
      data: EditHolidaysResolverService
    }
  },
  {
    path: 'businesses/:businessId/opening-hours/:openingHourId/edit',
    loadChildren: () => import('./business-owner/business/edit-opening-hours/edit-opening-hours.module').then(m => m.EditOpeningHoursPageModule),
    canActivate: [BusinessOwnerAuthGuard],
    resolve: {
      data: EditOpeningHoursResolverService
    }
  }
  ,
  {
    path: 'operator',
    loadChildren: () => import('./business-operator/business/overview/overview.module').then(m => m.OverviewPageModule),
    canActivate: [BusinessOperatorAuthGuard],
    resolve: {
      data: BusinessOperatorResolverService
    }

  }
  ,
  {
    path: 'businesses/:businessId/tables',
    canActivate: [BusinessOwnerAuthGuard],
    loadChildren: () => import('./business-owner/business/add-table/add-table.module').then(m => m.AddTablePageModule),
    resolve: {
      data: TableByBusinessResolverService
    }
  },
  {
    path: 'businesses/:businessId/privileges',
    canActivate: [BusinessOwnerAuthGuard],
    loadChildren: () => import('./business-owner/business/privileges/privileges.module').then(m => m.PrivilegesPageModule),
    resolve: {
      data: PrivilegeAllResolverService
    }
  }
  ,
  {
    path: 'businesses/:businessId/operator',
    canActivate: [BusinessOperatorAuthGuard],
    loadChildren: () => import('./business-operator/business/business-operator/business-operator.module').then(m => m.BusinessOperatorPageModule),
    resolve: {
      data: BusinessByIdResolverService
    }
  },
  {
    path: 'businesses/:businessId/operator/tables',
    canActivate: [BusinessOperatorAuthGuard],
    loadChildren: () => import('./business-operator/business/table-management/table-management.module').then(m => m.TableManagementPageModule),
    resolve: {
      data: TableByBusinessResolverService
    }
  },
  {
    path: 'businesses/:businessId/operator/tables/:tableId',
    canActivate: [BusinessOperatorAuthGuard],
    loadChildren: () => import('./business-operator/business/table-management/table-management.module').then(m => m.TableManagementPageModule),
    resolve: {
      data: TableByBusinessResolverService
    }
  },
  {
    path: 'business-not-found',
    loadChildren: () => import('./public/page-not-found/page-not-found.module').then(m => m.PageNotFoundPageModule)
  },
  {
    path: 'b/:url/:tableId',
    loadChildren: () => import('./public/ordering/ordering.module').then(m => m.OrderingPageModule)
  },
  {
    path: 'businesses/:businessId/payment-account',
    loadChildren: () => import('./business-owner/business/payment-account/payment-account.module').then(m => m.PaymentAccountPageModule),
    canActivate: [PaymentFeatureGuard],
  },
  {
    path: 'businesses/:businessId/publish-menu',
    loadChildren: () => import('./business-owner/business/publish-menu/publish-menu.module').then(m => m.PublishMenuPageModule),
    resolve: {
      data: VersionedMenuResolverService
    }
  },
  {
    path: 'businesses/:businessId/menu-preview/:versionId',
    loadChildren: () => import('./business-owner/business/menu-preview/menu-preview.module').then(m => m.MenuPreviewPageModule),
    resolve: {
      data: PreviewVersionedMenuResolverService
    }
  }




]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload', useHash: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
