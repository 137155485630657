import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginService } from '../services/auth/login.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessOwnerAuthGuard implements CanActivate {

  constructor(private router: Router, private auth: AuthService, private loginService: LoginService) {

  }
  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAuthenticationAndRole();
  }

  private checkAuthenticationAndRole(): Observable<boolean | UrlTree> {
    return this.auth.isAuthenticated$.pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) {
          return of(this.router.createUrlTree(['/login']));
        }
        return this.auth.idTokenClaims$.pipe(
          map(claims => {
            const decodedToken = this.loginService.decodeJwtPayload(claims.__raw);
            const roles = decodedToken.realm_access.roles;
            if (roles?.includes(environment.ROLES.BUSINESS_OWNER)) {
              return true;
            } else {
              return this.router.createUrlTree(['/unauthorized']); // or redirect to an appropriate route
            }
          })
        );
      }),
      catchError((error) => {
        return of(this.router.createUrlTree(['/login']));
      })
    );
  }
}
