export * from './business.service';
import { BusinessService } from './business.service';
export * from './businessOwner.service';
import { BusinessOwnerService } from './businessOwner.service';
export * from './businessPage.service';
import { BusinessPageService } from './businessPage.service';
export * from './holidays.service';
import { HolidaysService } from './holidays.service';
export * from './menu.service';
import { MenuService } from './menu.service';
export * from './menuItem.service';
import { MenuItemService } from './menuItem.service';
export * from './menuSection.service';
import { MenuSectionService } from './menuSection.service';
export * from './openingHours.service';
import { OpeningHoursService } from './openingHours.service';
export * from './order.service';
import { OrderService } from './order.service';
export * from './table.service';
import { TableService } from './table.service';
export const APIS = [BusinessService, BusinessOwnerService, BusinessPageService, HolidaysService, MenuService, MenuItemService, MenuSectionService, OpeningHoursService, OrderService, TableService];
