import { Component } from '@angular/core'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { environment } from 'src/environments/environment'
import { AppPage } from './class/app-pages/app-page'
import { UserData } from './class/responses/user'
import { LoginService } from './services/auth/login.service'
import { EventService } from './services/event/event.service'
import { AuthService } from '@auth0/auth0-angular'
import { Observable, of } from 'rxjs'
import { Platform } from '@ionic/angular'
import { BrowserDetectService } from './services/browser/browser-detect.service'
import { UserIdService } from './services/user/user-id.service'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userData: UserData
  metadata = {}
  roles: string[] = []
  private rolesCache$: Observable<string[]>

  public appPages: Array<AppPage> = [
    { title: 'Startseite', url: '/businesses', icon: 'home', requiredRole: [environment.ROLES.BUSINESS_OWNER] },
    { title: 'Startseite', url: '/operator', icon: 'home', requiredRole: [environment.ROLES.BUSINESS_OPERATOR] },
    { title: 'Lokal hinzufügen', url: '/businesses/add', icon: 'create', requiredRole: [environment.ROLES.BUSINESS_OWNER] }
  ]
  public additionalAppPages: Array<AppPage> = []
  public page: string = "/"
  public showSideMenu: boolean = false
  public businessId: number

  constructor(
    private events: EventService,
    private loginService: LoginService,
    private translate: TranslateService,
    private auth: AuthService,
    private browserDetectService: BrowserDetectService,
    private userIdService: UserIdService,
    private platform: Platform,
    private router: Router
  ) {
    this.initializeApp();
    this.subscribeUserEvent()
    this.subscribeAppPageEvent()

    this.translate.setDefaultLang('de')
    this.translate.use('de')

    this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.checkRoute()
        this.loginService.getRoles().subscribe(roles => {
          this.roles = roles
        })
        this.initalLoadUserData()
        this.browserDetectService.isUsableBrowser()
      } else {
        this.showSideMenu = false
      }

    })
  }
  checkRoute() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.urlAfterRedirects;
        const regex = /^\/b\/[^\/]+\/.*$/;
        const matches = regex.test(currentUrl);
        if (!matches) {
          this.showSideMenu = true
        }
      }
    });

  }


  initializeApp() {
    this.platform.ready().then(() => {
      setTimeout(() => {
        const splashScreenElement = document.getElementById('splash-screen');
        if (splashScreenElement) {
          splashScreenElement.style.display = 'none';
        }
      }, 1000);
    });
  }

  private initalLoadUserData() {

    this.userData = new UserData()
    this.auth.user$.subscribe(user => {
      this.userData.email = user.email
      this.userData.name = "Servito"//user.nickname 
    })

  }

  private subscribeUserEvent() {
    this.events.getUserDataSubject().subscribe((userData: UserData) => {
      this.showSideMenu = true
      this.userData = userData
    })
  }
  private subscribeAppPageEvent() {
    this.events.getAppPageSubject().subscribe((appPages: Array<AppPage>) => {
      this.additionalAppPages = appPages
    })
  }

  showMenuItem(roles: string[]): boolean {
    return roles.some(role => this.roles.includes(role))
  }


  logout() {
    this.auth.logout({ logoutParams: { returnTo: window.location.origin } })
  }


}
