import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MenuResponse, MenuService } from 'src/generated-sources/business-openapi';
import { ApiConfigService } from '../../config/api-config.service';

@Injectable({
  providedIn: 'root'
})
export class MenuByIdResolverService implements Resolve<MenuResponse> {

  constructor(private apiConfigService: ApiConfigService, private menuApi: MenuService) {
    this.menuApi.configuration = this.apiConfigService.getBusinessApiConfig()
  }

  resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    let businessId = Number(route.paramMap.get('businessId'))
    let menuId = Number(route.paramMap.get('menuId'))
    return this.menuApi.getMenuById(businessId, menuId)
  }
}